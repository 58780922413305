<template>
    <v-container class="article">
        <v-row>
            <v-col cols="12" class="text-center secondary-font px-0">
                <v-card class="rounded-lg clickable">
                    <v-img
                        :src="getImage()"
                        aspect-ratio="1"
                        class="white--text align-end"
                        min-height="550px"
                        max-height="550px"
                    >
                        <v-card-title class="custom-orange text-h6" style="text-align: justify; word-break: break-word;" v-text="article.title" />
                        <v-card-subtitle class="custom-orange" style="text-align: end; word-break: break-word;"> {{ getDate() }} </v-card-subtitle>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="white rounded-lg" justify="center" align="center">
            <v-col cols="12" style="font-size: 18px; overflow-x:hidden;" align="center" class="py-10">
                <span style="font-family: Ubuntu, Arial, sans-serif !important; text-align: center;" v-html="article.body" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import articleService from '@/services/article'
    export default {
        data() {
            return {
                article: null
            }
        },
        watch: {
            '$route.params.id': {
                handler() {
                    this.getArticle()
                }
            }
        },
        created() {
            this.getArticle()
        },
        methods: {
            getImage() {
                if (this.article.image) {
                    return `https://api.aofloisvos.gr/uploads/images/${this.article.image.imageName}`
                } else {
                    return require('@/assets/main_logo.png')
                }
            },
            getDate() {
                const date = new Date(this.article.articleDate)
                return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
            },
            getArticle() {
                articleService.getArticle(this.$route.params.id).then(resp => {
                    this.article = resp.data.article
                })
            }
        }
    }
</script>
